/* eslint-disable */
import { ActionTypes } from "../actions/campaignAction";

const initailData = {
    campaignList: [],
    CurrenUrlBase: "",
    campaignListIdWise: {},
    campaignOptionList:[],
    mosaicList:[],
    mailCampaignList:[],
    sponsorsData: {facefunding_sponsors:[]},
    blogList: {
        data:[],
        pagination:{
            total_records: "",
            record_limit: "",
            current_page: "",
        }
    },
};

export const campaignReducers = (state = initailData, action) => {
    switch (action.type) {
        case ActionTypes.GET_CAMPAIGN_LIST:
            return {
                ...state,
                campaignList: action.payload,
            };
        case ActionTypes.CAMPAIGN_CURRENT_TAB:
            return {
                ...state,
                CurrenUrlBase: action.payload,
            };
        case ActionTypes.ADD_CAMPAIGN_NEW_LIST:
            const oldCampaign = [...state.campaignList];
            oldCampaign.unshift(action.payload);
            return {
                ...state,
                campaignList: oldCampaign,
            };
        case ActionTypes.UPDATE_CAMPAIGN_NEW_LIST:
            const campaignListOld = [...state.campaignList]; 
            const updatecampaignList = campaignListOld.map((elm) => {
                if(elm?.id === action?.payload?.id) {
                    return{
                        ...elm,
                        title: action?.payload?.title,
                        subtitle: action?.payload?.subtitle,
                        description: action?.payload?.description,
                        athlete: JSON.stringify(action?.payload?.athlete),
                        charity: JSON.stringify(action?.payload?.charity),
                        is_active: action?.payload?.is_active
                    }
                }else 
                return elm;
            });
            
            return {
                ...state,
                campaignList: updatecampaignList,
            };
        case ActionTypes.SET_STORE_CAMPAIGN_LIST_OPTION:{
            const list = action?.payload.map((item) => {
                return{
                    ...item,
                    label: item?.title,
                    value: item?.id,
                }
            });
            
            return {
                ...state,
                campaignOptionList: list,
            };
        }
        case ActionTypes.GET_CAMPAIGN_LIST_ID_WISE:
            const getCampaignObject = {};
            // console.log("campaignListIdWise", state?.campaignListIdWise)
            action.payload && action.payload.map((elm) => {
                getCampaignObject[elm.slug] = elm;
            });
            return {
                ...state,
                campaignListIdWise: getCampaignObject,
            };
        case ActionTypes.DELETE_CAMPAIGN_LIST:
            const campaignListLists = [...state.campaignList]; 
            const NewUpdatedCampaignListLists = [];
            campaignListLists && campaignListLists.filter((elm) => {
                if(elm?.id !== action.payload.id) {
                    return NewUpdatedCampaignListLists.push(elm);
                }
            });

            return {
                ...state,
                campaignList: NewUpdatedCampaignListLists,
            };
        case ActionTypes.SET_STORE_UPDATE_CAMPAIGN_SEQUENCE:{
            const data = state?.campaignList?.map((elm) => {
                if(elm?.id == action.payload.id) {
                    return{
                        ...elm,
                        sequence_no: action?.payload?.sequence_no,
                    }
                }
                return elm
            });

            return {
                ...state,
                campaignList: data,
            };
        }
        case ActionTypes.SET_STORE_MAIL_CAMPAIGN_LIST:
            return {
                ...state,
                mailCampaignList: action.payload,
            };
        case ActionTypes.SET_STORE_MOSAIC_LIST:
            return {
                ...state,
                mosaicList: action.payload,
            };
        case ActionTypes.SET_STORE_SPONSORS_LIST_DATA:{
            return {
                ...state,
                sponsorsData: action?.payload,
            };
        }
        case ActionTypes.SET_STORE_BLOG_LIST_DATA:{
            return {
                ...state,
                blogList: action?.payload ?action?.payload: initailData.blogList,
            };
        }
        case ActionTypes.SET_STORE_UPDATE_BLOG_DETAILS:{
            console.log("action?.payload", action?.payload, state?.blogList)
            const blogDetails = state?.blogList?.data?.map((item, index)=>{
                if(item.id == action?.payload?.id){
                    return action?.payload
                }else{
                    return item
                }
            })
            return {
                ...state,
                blogList:{
                    ...state?.blogList,
                    data: blogDetails,
                }
            };
        }
        default:
        return state;
    }
}