import moment from "moment";
import { GET_DONATION_LIST_API_URL } from "../components/Shared/constant";

const GetFundDonations = async (accessToken, userSearch, userType, userLimit, currentPage, { signal } = {}, param, paymentMethod) => {
  const payload = {
    start_date:"0",
    end_date:"0",
    res:"json",
    campaign:"all"
  }
  if(param?.start_date){
    payload.start_date = moment(param?.start_date).format("YYYY-MM-DD")
  }
  if(param?.end_date){
    payload.end_date = moment(param?.end_date).format("YYYY-MM-DD")
  }
  if(param?.res){
    payload.res = param?.res
  }
  if(param?.campaign){
    payload.campaign = param?.campaign
  }
  const responce = window.axios.get(`${GET_DONATION_LIST_API_URL}/${userType}/${payload.campaign}/${paymentMethod}/${payload?.start_date}/${payload?.end_date}/${userLimit}/${currentPage}/${userSearch === "" ? "0" : userSearch}/${payload.res}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    },
    signal:signal,
  }).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default GetFundDonations;