import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import DefaultProduct from "./../../assets/img/DefaultProduct.jpg";
import CommonLoader from "../Common/CommonLoader";
// import NOImg  from "../../assets/img/nope-not-here.webp"
import axios from "axios";
import { useSelector } from "react-redux";


export default function ModalPreview({ onClose, data }) {
  const [loaded, setLoaded] = useState(false);
  const [loaded1, setLoaded1] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [mosaci,setMosaic] = useState(false);
  const [blended,setIsBlended] = useState(false);
  const { accessToken } = useSelector((state) => state.personalReducers);
  const [load,setLoad] = useState(false)

  // console.log("data", data);

  const handleImageLoaded = () => {
    setLoaded(true);
  };
  const handleImageLoaded1 = () => {
    setLoaded1(true);
    
  };
  const handleImageLoaded3 = () => {
    setLoaded3(true);
    
  };
  const handleImageError = (e) => {
    e.target.src = DefaultProduct;
    setLoaded(true); // set loaded to true even if there's an error to stop loader
  };
  const handleImageErrorMosaci = (e) =>{
    e.target.src = DefaultProduct;
    setMosaic(true)
    setLoaded1(true);


  }
  const handleImageErrorBlended = (e) =>{
    e.target.src = DefaultProduct;
    setLoaded3(true);
    setIsBlended(true)

  }

  const setDownloadURL = (url) => {
    window.open(url);
  };

  const downloadImage = (url) => {
    if (!url) return;

    // Open the image in a new tab
    const newTab = window.open(url, '_blank');
    
    // Wait for the new tab to load and then trigger the download
    newTab.onload = () => {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'blended-image.jpg'; // Set a default file name
      anchor.click();
    };
  
  };
  // const  downloadImage2 =  (url, filename) => {
    
  //   // Create an anchor element
  //   var a = document.createElement('a');
  //   a.style.display = 'none';
  //   document.body.appendChild(a);
  //   // Set the HREF to the image URL and specify filename
  //   a.href = url;
  //   a.download = filename;
  //   // Trigger the click event to download the image
  //   a.click();
  //   // Clean up
  //   document.body.removeChild(a);
  // }
  
  
  const DonwloadFile = async(blend_url,accessToken) => {

    const authurl = blend_url;
    const url = await GetViewFilesAPI(authurl, accessToken);
    const parsedUrl = new URL(blend_url);
      const fileName = parsedUrl.pathname.split('/').pop();
    if(url) {

        var tempEl = document.createElement("a");
        tempEl.href = url;
        tempEl.download = fileName;
        tempEl.click();
        window.URL.revokeObjectURL(url);
        setLoad(false)

    };
};
  const GetViewFilesAPI = async (url, qr_token) => {
    setLoad(true)
    try {
      const options = {
        headers: {
          'Authorization': `Bearer ${qr_token}`
        },
        responseType: 'blob',

      };
      const response = await axios.get(url, options);
      // console.log("url==========>",response)
      // console.log("response", response)
      const file = window.URL.createObjectURL(response.data);
      // console.log("file",file)

      return file;
    } catch (error) {
      return error;
    }
  };
  // console.log("blended data",data)
  // console.log("data?.blend_url",data?.blend_url)
  return (
    <Modal
      centered
      className="modal-preview"
      show={true}
      onHide={() => onClose(null)}
    >
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={() => onClose(null)}
      ></button>
      <Modal.Body>
        <Row className="m-0">
          <div className={`${data?.is_blended ? "col-4" : "col-6"} `}>
            <h5>Source Image</h5>
           
            <img
              onLoad={handleImageLoaded}
              src={data?.source_url}
              alt={data?.source_url}
              onError={handleImageError}
              className="image-preview w-100"
              style={{ objectFit: "cover", height: "70%" }}
            />
            <button
              style={{
                margin: "10px",
              }}
              className="btn btn-primary cmnfilterHeadbtn"
              onClick={() => setDownloadURL(data?.source_url)}
            >
              Download Source
            </button>
          </div>
          <div className={`${data?.is_blended ? "col-4" : "col-6"} `}>
         
            <h5>Mosaic Image</h5>
            {
              !data?.mosaic_url ?
                    <div style={{
                         display:"flex",
                         flexDirection:"column",
                         alignItems: "center",
                         height:"70%",
                         justifyContent:"center",
                          padding:"10px",
                          border: "1px solid gray",
              }}>

               <p
                      style={{
                        textAlign: "center",
                        marginBottom:"0px"

                      }}
                    >
                      Mosaic  effect is in progress. Usually it takes 7 to 10
                      minutes to get generate. 
                    </p>
                    <p>Thank you for your patience.</p>
                    </div>:
                    data?.mosaic_url && mosaci ? 
                    <>
                    <div style={{
                display:"flex",
                flexDirection:"column",
                alignItems: "center",
                height:"70%",
                justifyContent:"center",
                border: "1px solid gray",
                borderRadius: "10px",
                 padding:"10px"
              }}>

               <p
                      style={{
                        textAlign: "center",
                        marginBottom:"0px"

                      }}
                    >
                      The file size is too large to display a preview. Please download the image to view it.
                    </p>

                    </div>
                    <button
              style={{
                margin: "10px",
              }}
              className="btn btn-primary cmnfilterHeadbtn"
              onClick={() => setDownloadURL(data?.mosaic_url)}
            >
              Download Mosaic
            </button>
                    </>

                    :
                    <>

            <img
              className="image-preview w-100"
              style={{ objectFit: "cover", height: "70%" }}
              src={data?.mosaic_url}
              alt={data?.mosaic_url}
              onLoad={handleImageLoaded1}
              // onError={handleImageError}
              onError={handleImageErrorMosaci}
            />

            <button
              style={{
                margin: "10px",
              }}
              className="btn btn-primary cmnfilterHeadbtn"
              onClick={() => setDownloadURL(data?.mosaic_url)}
            >
              Download Mosaic
            </button>
                    </>
            }
          </div>



          {data?.is_blended && (
            <div className="col-4">
              <h5>Blended Image </h5>
              {data?.blend_url ? (
                <>
                  {data?.blend_url && blended  ? (
                    <>
                    
                    <div style={{
                display:"flex",
                flexDirection:"column",
                alignItems: "center",
                height:"70%",
                justifyContent:"center",
                border: "1px solid gray",
                borderRadius: "10px",
                 padding:"10px"

              }}>

               <p
                      style={{
                        textAlign: "center",
                        marginBottom:"0px"

                      }}
                    >
                      The file size is too large to display a preview. Please download the image to view it.


                    </p>
                    </div>
                    <button
                        style={{
                          margin: "10px",
                        }}
                        className="btn btn-primary cmnfilterHeadbtn"
                        onClick={()=>DonwloadFile(data?.blend_url,accessToken)}
                        disabled={load}
                        >
                        Download Blended
                      </button>
                    </>

                  ) : (
                    <>
                      <img
                        className="image-preview w-100"
                        style={{ objectFit: "cover", height: "70%" }}
                        src={data?.blend_url}
                        alt={data?.blend_url}
                        onError={handleImageErrorBlended}
                        onLoad={handleImageLoaded3}

                      />
                      <button
                        style={{
                          margin: "10px",
                        }}
                        className="btn btn-primary cmnfilterHeadbtn"
                      //  onClick={() => downloadImage(data?.blend_url)}
                      onClick={()=>DonwloadFile(data?.blend_url,accessToken)}
                      disabled={load}
                      >
                        Download Blended
                      </button>
                    </>
                  )}
                </>
              ) :
              <div style={{
                display:"flex",
                flexDirection:"column",
                alignItems: "center",
                height:"70%",
                justifyContent:"center",
                padding:"10px",
                border: "1px solid gray",
              }}>

               <p
                      style={{
                        textAlign: "center",
                        marginBottom:"0px"

                      }}
                    >
                      Blending effect is in progress. Usually it takes 7 to 10
                      minutes to get generate. 
                    </p>
                    <p>Thank you for your patience.</p>
              </div>
                    }
            </div>
          )}
        </Row>
        {(!loaded1 || !loaded) && <CommonLoader classNameS={"charityLoader"} />}
      </Modal.Body>
    </Modal>
  );
}
