/* eslint-disable */
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/img/edit.png";
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { useDispatch, useSelector } from "react-redux";
import { SelectCampaignTab, setStoreUpdateCampaignSequence } from "../../redux/actions/campaignAction";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import DeleteIcon from "../../assets/img/delete.png";
import { PostRequestAPI } from "../../Api/PostRequest";
import { toast } from 'react-toastify';
import { EDIT_CAMPAIGN_API_URL } from "../Shared/constant";

const CampaignItem = (props) => {
    const { user, DeleteCampaign, CharityActivStatus } = props
    // const [DigitValue, setDigitValue] = useState(user?.sequence_no);
    const [Loader, setLoader] = useState(false);
    const { accessToken } = useSelector((state) => state.personalReducers);

    const dispatch = useDispatch();
    // const onChange = (event) =>{
    //     const inputValue = event.target.value;
    //     const digitOnly = inputValue.replace(/\D/g, '');

    //     if (digitOnly !== '') {
    //         const digitValue = parseInt(digitOnly, 10);
    //         if (digitValue > 0) {
    //             setDigitValue(digitValue);
    //         } else {
    //             setDigitValue('');
    //         }
    //     } else {
    //     setDigitValue('');
    //     }
    // }
    // const callUpdateIndex = async (e) =>{
    //     e.preventDefault();
    //     if(Loader == false){
    //         setLoader(true);
    //         const charityData = JSON.parse(user.charity.replace("None", 'null').replace("False", 'false').replace("True", 'true')?.replaceAll(/'/g, '"'));
    //         const athleteData = JSON.parse(user.athlete.replace("None", 'null').replace("False", 'false').replace("True", 'true')?.replaceAll(/'/g, '"'));
    //         // console.log("user", user, charityData, athleteData)

    //         const jsonData = {
    //             slug: user?.slug,
    //             title: user?.title,
    //             subtitle: user?.subtitle,
    //             description: user?.description,
    //             is_active: user?.is_active ? 1 : 0,
    //             athlete: athleteData,
    //             charity: charityData,
    //             sequence_no: DigitValue,
    //         };
    //         const response = await PostRequestAPI(EDIT_CAMPAIGN_API_URL, jsonData, accessToken);
    //         // dispatch(setStoreUpdateCampaignSequence({id: user?.id, sequence_no: DigitValue}))
    //         if(response?.status == 200){
    //             toast.success(response?.data?.msg)
    //             dispatch(setStoreUpdateCampaignSequence({id: user?.id, sequence_no: DigitValue}))
    //         }else{

    //         }
    //         // console.log("response", response)
    //         setLoader(false);
    //     }
    // }
// console.log("user",user?.charity)
    return(<>
        <tr>
            <td>{user?.title}</td>
            <td>{user?.subtitle}</td>
            <td>{moment(user.created_at).format('LL HH:mm A')}</td>
            <td>
                {/* <Form className="switchbtn" onSubmit={callUpdateIndex}>
                    <Form.Control 
                        style={{width: 60, boxShadow:"none", fontSize:12}}
                        onChange={onChange}
                        value={DigitValue}
                        size="sm"
                        type="text"
                    />
                    {(DigitValue != user?.sequence_no && DigitValue!==null && DigitValue!=="") && (
                        <button disabled={Loader} className={"btn btn-primary btn-sm ms-2 cmnfilterHeadbtn"} type="submit">
                            Save
                        </button>
                    )}
                </Form> */
                }
                {user?.sequence_no ? user?.sequence_no : "-"}
            </td>
            <td>
                <Form className="switchbtn">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        checked={user?.is_active}
                        onChange={e => {}}
                        onClick={() => CharityActivStatus(user)}
                    />
                    <Badge bg={user?.is_active ? "primary" : "danger"}>{user?.is_active ? "Yes" : "No"}</Badge>
                </Form>
            </td>
            <td className="space-nowrap text-nowrap">
                <div className="d-flex">
                    <Link className="btn actionbtn" onClick={() => dispatch(SelectCampaignTab("edit-campaign"))} to={"/edit/campaign/" + user?.slug} >
                        <Image src={EditIcon} alt="edit icon" />
                    </Link>
                    <button className="btn actionbtn" onClick={() => DeleteCampaign(user)}>
                        <Image src={DeleteIcon} alt="delete icon" />
                    </button> 
                </div>
            </td>
        </tr>
    </>);
}

export default CampaignItem;