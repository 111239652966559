/* eslint-disable */
// empty space validation fucntion
const CheckValid = (value, states) => {
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    const YouTubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/; // eslint-disable-line
    const VimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?)/;
    // email validation
    if(states?.type === "email") {
        if(value !== "") {
            if(EmailRegex.test(value) === true) {
                return states?.error("");    
            } else {
                if(EmailRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Email id");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if(value === "") {
                return states?.error("Email id cannot be empty");
            } else if(EmailRegex.test(value) === false) {
                return states?.error("Please Enter Valid Email id");
            } else {
                return states?.error("");
            }
        };
    };

    // password validation
    if(states?.type === "password"){
        if(value !== "") {
            if(PasswordRegex.test(value) === true) {
                return states?.error("");
            } else {
                if(PasswordRegex.test(value) === false) {
                    return states?.error("Please Enter Valid Password");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Password cannot be empty");
            } else if(PasswordRegex.test(value) === false) {
                return states?.error("Please Enter Valid Password");
            } else {
                return states?.error("");
            }
        }
    }

    // first name validation
    if(states?.type === "fname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("First name cannot be empty");
        }
    }
    // last name validation
    if(states?.type === "SponsorsTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Sponsors title cannot be empty");
        }
    }
    // last name validation
    if(states?.type === "lname"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("First name cannot be empty");
        }
    }

    // amount validation
    if(states?.type === "amount"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Amount cannot be empty");
        }
    }

    // phone validation
    if(states?.type === "phone"){
        if(value !== "") {
            if(value.length === "10") {
                return states?.error("");
            } else {
                if(value.length < 10) {
                    return states?.error("Please enter 10 digit phone number");
                } else {
                    return states?.error("");
                }
            }
        } else {
            if (value === "") {
                return states?.error("Phone number cannot be empty");
            } else if(value.length < 10) {
                return states?.error("Please enter 10 digit phone number");
            } else {
                return states?.error("");
            }
        }
    }

    // country validation
    if(states?.type === "country"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Country cannot be empty");
        }
    }

    // category name validation
    if(states?.type === "categoryname"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Category cannot be empty");
        }
    }

    // select category validation
    if(states?.type === "selectcategoryname"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Please select category");
        }
    }

    // fundraise for validation
    if(states?.type === "fundraiseforname") {
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Please select fundraise for");
        }
    }

    // name validation
    if(states?.type === "name"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Name cannot be empty");
        }
    }

    // EIN validation
    if(states?.type === "EIN"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("EIN cannot be empty");
        }
    }

    //description validation
    if(states?.type === "Description"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Description cannot be empty");
        }
    }

    //avatar validation
    if(states?.type === "avatar"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Please Upload Photo");
        }
    }

    //avatarCharity validation
    if(states?.type === "avatarCharity"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Please Upload Photo");
        }
    }
    if(states?.type === "sponsorLogo"){
        if(value?.length>0) {
            return states?.error("");
        } else {
            return states?.error("Please Upload Photo");
        }
    }

    //coverMedia validation
    if(states?.type === "coverMedia"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Please Upload Photo Or YouTube Video Link");
        }
    }

    //address validation
    if(states?.type === "address"){
        if(value !== "" && value !== undefined) {
            return states?.error("");
        } else {
            return states?.error("Address cannot be empty");
        }
    }

    // first name validation

    if(states?.type === "youtubelink"){
        if(value !== "") {
            if(YouTubeRegex.test(value) === true) {
                return states?.error("");
            }else {
                return states?.error("Please Enter Valid YouTube Link");
            }
        } else {
            return states?.error("Please Enter YouTube Link");
        }
    }
    // first name validation

    if(states?.type === "vimeo_link"){
        if(value !== "") {
            if(VimeoRegex.test(value) === true){
                return states?.error("");
            }else {
                return states?.error("Please Enter Valid Vimeo Link");
            }
        } else {
            return states?.error("Please Enter Vimeo Link");
        }
    }
    


    // CampaignTitle validation
    if(states?.type === "CampaignTitle"){
        if(value !== "" && value ) {
            return states?.error("");
        } else {
            return states?.error("Campaign title cannot be empty");
        }
    }
    // if (states?.type === "CampaignTitle") {
    //     if (value.length >= 20 && value.length <= 39) {
    //         states?.error("");
    //     } else if (value === "") {
    //         states?.error("Campaign title cannot be empty");
    //     } else {
    //         states?.error("Campaign title must be between 20 and 39 characters");
    //     }
    // }

    if(states?.type === "SequenceNumber"){
        if(value !== "" && value ) {
            return states?.error("");
        } else {
            return states?.error("Sequence number cannot be empty");
        }
    }
    // CampaignTitle validation
    if(states?.type === "CampaignSubTitle"){
        if(value !== "" && value) {
            return states?.error("");
        } else {
            return states?.error("Campaign SubTitle cannot be empty");
        }
    }

    // CampaignDescription validation
    if(states?.type === "CampaignDescription"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign description cannot be empty");
        }
    }

    // CampaignAthleteTitle validation
    if(states?.type === "CampaignAthleteTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign athlete title cannot be empty");
        }
    }

    // CampaignAthleteSubTitle validation
    if(states?.type === "CampaignAthleteSubTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign athlete SubTitle cannot be empty");
        }
    }

    // CampaignAthleteDescription validation
    if(states?.type === "CampaignAthleteDescription"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign athlete description cannot be empty");
        }
    }

    // CampaignCharityTitle validation
    if(states?.type === "CampaignCharityTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign Charity title cannot be empty");
        }
    }

    // CampaignCharitySubTitle validation
    if(states?.type === "CampaignCharitySubTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign Charity SubTitle cannot be empty");
        }
    }

    // CampaignCharityDescription validation
    if(states?.type === "CampaignCharityDescription"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign Charity description cannot be empty");
        }
    }

    // CampaignAthleteBio validation
    if(states?.type === "CampaignAthleteBio"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign athlete bio cannot be empty");
        }
    }

    // CampaignCharityBio validation
    if(states?.type === "CampaignCharityBio"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Campaign charity bio cannot be empty");
        }
    }
    // if(states?.type === "CommunityImage"){
    //     if(value !== "") {
    //         return states?.error("");
    //     } else {
    //         return states?.error("Community image cannot be empty");
    //     }
    // }
    if(states?.type === "CommunityDescription"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Community description cannot be empty");
        }
    }
    if(states?.type === "CommunityTitle"){
        if(value !== "") {
            return states?.error("");
        } else {
            return states?.error("Community title cannot be empty");
        }
    }
};

export default CheckValid;