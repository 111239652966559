/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PackageContent from './PackageContent';
import FreePackageContent from './FreePackageContent';
import GetCampaign from '../../Api/GetCampaign';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreCampaignListOption } from '../../redux/actions/campaignAction';
import { PACKAGE_MANAGEMENT } from '../Shared/constant';

export default function SettingTab() {
    const location = useLocation();
    const { accessToken } = useSelector((state) => state.personalReducers);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [tabSelect, setTabSelect] = useState(location?.state || "paid_coupon");
    const listTab = [
        {
            title:"Paid Packages",
            key:"paid_coupon"
        },
        {
            title:"Free Coupon",
            key:"free_coupon"
        },
    ];
    useEffect(()=>{
        getDonationModel(1, 5000, "all");
    },[])

    // Get data from api
    const getDonationModel = async (currentPage,userLimit,userType) => {
        const responce = await GetCampaign(accessToken, "", userType, userLimit, currentPage );
        if(responce?.status === 200) {
            dispatch(setStoreCampaignListOption(responce.data));
        } else {
            dispatch(setStoreCampaignListOption([]));
        };
    };
    
  return (
    <React.Fragment>
        <ul className="tabcomnsection">
            {listTab?.map((item , index)=>(
                <React.Fragment key={index}>
                    <li className={`${tabSelect == item?.key && "active"} text-capitalized`} onClick={()=>{
                        setTabSelect(item?.key);
                        navigate(PACKAGE_MANAGEMENT, {state: item?.key})
                    }} >
                        {item?.title}
                    </li>
                </React.Fragment>
            ))}
        </ul>
        {tabSelect === "paid_coupon" && (
            <PackageContent/>
        )}
        {tabSelect === "free_coupon" && (
            <FreePackageContent/>
        )}
    </React.Fragment>
  )
}
